import React, { useCallback, useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "./Form";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { languagesEndpoint } from "../../Services/apiEndpoint";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "85vh!important",
    maxHeight: "85vh!important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit({ open, setOpen, entry, onEdit }) {
  const classes = useStyles();
  const { homeDispatch } = useContext(HomeAppContext);
  const [errors, setErrors] = useState([]);

  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const getEntry = useCallback(() => {
    if (entry !== null) {
      setLoading(true);
      setInternalEntry(null);
      api.get(`${languagesEndpoint}/${entry.id}`).then((r) => {
        setInternalEntry({
          ...r.data,
          externalId: r.data.externalId || undefined,
          iso639Dash1: r.data.iso639Dash1 || undefined,
          iso639Dash2: r.data.iso639Dash2 || undefined,
          shortName: r.data.shortName || undefined,
          code: r.data.code || undefined,
          xtrfClient: r.data.xtrfClient || undefined,
          tmsClient: r.data.tmsClient || undefined,
          salesforceClient: r.data.salesforceClient || undefined,
          writingDirection: r.data.writingDirection || {},
          writingSystem: r.data.writingSystem || {},
        });
        setErrors([]);
        setLoading(false);
        setSubmitted(false);
      })
        .catch((error) => {
          errorManagement.formErrors(error, homeDispatch);
        });
    }
  }, [entry, homeDispatch]);

  React.useEffect(() => {
    if (open) getEntry();
  }, [getEntry, open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
    setInternalEntry(null);
  };

  const handleCloseSave = () => {
    const updateEntry = {
      ...internalEntry,
      externalId: internalEntry.externalId || null,
      xtrfClient: internalEntry.xtrfClient || null,
      tmsClient: internalEntry.tmsClient || null,
      salesforceClient: internalEntry.salesforceClient || null,
      equivalencies: (internalEntry.equivalencies || []).map(x => x.id),
      writingDirection: internalEntry.writingDirection || null,
      writingSystem: internalEntry.writingSystem || null
    };

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating Mapping", loading: true },
    });
    setSubmitted(true);
    setLoading(true);
    api
      .put(`${languagesEndpoint}/${updateEntry.id}`, updateEntry)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Mapping updated", type: "success" },
        });
        setOpen(false);
        setLoading(false);
        setInternalEntry(null);
        onEdit();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-edit-entry"
    >
      <DialogTitle id="alert-dialog-edit-entry">
        {`Edit Mapping: ${entry.name}`} {entry.identifier ? `(${entry.identifier})` : ''}
      </DialogTitle>
      <DialogContent>
        <Form
          entry={internalEntry}
          errors={errors}
          setEntry={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseSave}
          color="primary"
          autoFocus
          disabled={loading || saveDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
