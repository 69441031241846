import { TextField, Divider, Tabs, Tab, Box } from "@mui/material";

import CustomAutocomplete from "../General/CustomAutocomplete";
import Grid from "@mui/material/Grid";
import React from "react";
import _ from "lodash";
import makeStyles from '@mui/styles/makeStyles';
import { appliesToEndpoint, toolsDropdownEndpoint, writingDirectionsEndpoint, writingSystemsEndpoint, xtrfClientsEndpoint } from "../../Services/apiEndpoint";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/styles";
import Equivalencies from "./Equivalencies/Equivalencies";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dropdowns: {
    marginTop: "16px"
  }
}));

const initialState = {
  tabIndex: 0,
  tabTransitionEnded: true,
  loadingAutomaticApprovers: true,
  countAutomaticApprovers: 0,
  countAutomaticResources: 0,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {index === 0 ? (
        <Box p={1}>{children}</Box>
      ) : (
        value === index && <Box p={1}>{children}</Box>
      )}
    </div>
  );
}

export default function Form({
  entry = null,
  errors = [],
  setEntry,
  loading,
  submitted,
  setSaveDisabled,
  onSetDirty = null,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [state, setState] = useState(initialState);

  const onValueChange = (value, property) => {
    setEntry({ ...entry, [property]: value });
    if (onSetDirty !== null) onSetDirty(true);
  };

  const onInputChange = (event, property) => {
    setEntry({ ...entry, [property]: event.target.value });
    if (onSetDirty !== null) onSetDirty(true);
  };

  const handleChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      tabIndex: newValue,
      tabTransitionEnded: false,
    }));
  };
  const handleChangeIndex = (index) => {
    setState((prevState) => ({
      ...prevState,
      tabIndex: index,
      tabTransitionEnded: false,
    }));
  };
  const onTransitionEnd = () => {
    setState((prevState) => ({
      ...prevState,
      tabTransitionEnded: true,
    }));
  };

  const onAllCheckboxChange = (event, property, related) => {
    const checked = event.target.checked;
    setEntry({
      ...entry,
      [property]: checked,
      [related]: [],
    });
  };

  const onEquivalenciesChange = (value) => {
    setEntry({
      ...entry,
      equivalencies: value
    });
  };

  return entry === null ? null : (
    <React.Fragment>
      <Tabs
        value={state.tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label="General" />
        <Tab label="Equivalencies" />
      </Tabs>

      <SwipeableViews
        containerStyle={{
          transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
        }}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={state.tabIndex}
        onChangeIndex={handleChangeIndex}
        onTransitionEnd={onTransitionEnd}
      >
        <TabPanel value={state.tabIndex} index={0} dir={theme.direction}>
          <Grid container className={classes.paper} spacing={2}>
            <Grid item sm={4} className={classes.dropdowns}>
              <CustomAutocomplete
                value={entry.tool}
                setValue={(value) => onValueChange(value, "tool")}
                related={null}
                url={toolsDropdownEndpoint}
                liveOptions={false}
                label={"Tool"}
                placeholder={"Select a tool"}
                noOptionsText={"No tool found"}
                loadingText={"Searching"}
                error={_.isArray(errors.Tool)}
                helperText={_.isArray(errors.Tool) && errors.Tool.join()}
                disableClearable={true}
                required={true}
                onLoading={setSaveDisabled}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                margin="normal"
                fullWidth
                error={_.isArray(errors.Name)}
                helperText={
                  _.isArray(errors.Name) && errors.Name.join()
                }
                disabled={loading}
                label="Name"
                value={entry.name}
                required={true}
                onChange={(event) => onInputChange(event, "name")}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                margin="normal"
                fullWidth
                error={_.isArray(errors.Identifier)}
                helperText={
                  _.isArray(errors.Identifier) && errors.Identifier.join()
                }
                disabled={loading}
                label="Identifier"
                value={entry.identifier}
                required={true}
                onChange={(event) => onInputChange(event, "identifier")}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                margin="normal"
                fullWidth
                error={_.isArray(errors.shortName)}
                helperText={
                  _.isArray(errors.shortName) && errors.shortName.join()
                }
                disabled={loading}
                label="Short Name"
                value={entry.shortName}
                onChange={(event) => onInputChange(event, "shortName")}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                margin="normal"
                fullWidth
                error={_.isArray(errors.code)}
                helperText={
                  _.isArray(errors.code) && errors.code.join()
                }
                disabled={loading}
                label="Code"
                value={entry.code}
                onChange={(event) => onInputChange(event, "code")}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                margin="normal"
                fullWidth
                error={_.isArray(errors.iso639Dash1)}
                helperText={
                  _.isArray(errors.iso639Dash1) && errors.iso639Dash1.join()
                }
                disabled={loading}
                label="ISO 639-1"
                value={entry.iso639Dash1}
                onChange={(event) => onInputChange(event, "iso639Dash1")}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                margin="normal"
                fullWidth
                error={_.isArray(errors.iso639Dash2)}
                helperText={
                  _.isArray(errors.iso639Dash2) && errors.iso639Dash2.join()
                }
                disabled={loading}
                label="ISO 639-2"
                value={entry.iso639Dash2}
                onChange={(event) => onInputChange(event, "iso639Dash2")}
              />
            </Grid>
            <Grid item sm={4} className={classes.dropdowns}>
              <CustomAutocomplete
                value={entry.appliesTo}
                setValue={(value) => onValueChange(value, "appliesTo")}
                related={null}
                url={appliesToEndpoint}
                liveOptions={false}
                label={"Applies to"}
                placeholder={"Select a target"}
                noOptionsText={"No target found"}
                loadingText={"Searching"}
                error={_.isArray(errors.appliesTo)}
                helperText={
                  _.isArray(errors.appliesTo) && errors.appliesTo.join()
                }
                disableClearable={true}
                required={true}
                onLoading={setSaveDisabled}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                margin="normal"
                fullWidth
                error={_.isArray(errors.externalId)}
                helperText={
                  _.isArray(errors.externalId) && errors.externalId.join()
                }
                disabled={loading}
                label="External Id"
                value={entry.externalId}
                onChange={(event) => onInputChange(event, "externalId")}
              />
            </Grid>
            <Grid item sm={4} className={classes.dropdowns}>
              <CustomAutocomplete
                value={entry.writingDirection}
                setValue={(value) => onValueChange(value, "writingDirection")}
                related={null}
                url={writingDirectionsEndpoint}
                liveOptions={false}
                label={"Writing Direction"}
                placeholder={"Select a writing direction"}
                noOptionsText={"No writing direction found"}
                loadingText={"Searching"}
                error={_.isArray(errors.WritingDirection)}
                helperText={
                  _.isArray(errors.WritingDirection) && errors.WritingDirection.join()
                }
                disableClearable={true}
                required={true}
                onLoading={setSaveDisabled}
              />
            </Grid>
            <Grid item sm={4} className={classes.dropdowns}>
              <CustomAutocomplete
                value={entry.writingSystem}
                setValue={(value) => onValueChange(value, "writingSystem")}
                related={null}
                url={writingSystemsEndpoint}
                liveOptions={false}
                label={"Writing System"}
                placeholder={"Select a writing system"}
                noOptionsText={"No writing system found"}
                loadingText={"Searching"}
                error={_.isArray(errors.WritingSystem)}
                helperText={
                  _.isArray(errors.WritingSystem) && errors.WritingSystem.join()
                }
                disableClearable={true}
                required={true}
                onLoading={setSaveDisabled}
              />
            </Grid>

            <Grid item sm={12}>
              <Divider />
            </Grid>

            <Grid item sm={4}>
              <CustomAutocomplete
                value={entry.xtrfClient}
                setValue={(value) => onValueChange(value, "xtrfClient")}
                related={null}
                url={xtrfClientsEndpoint}
                label={"XTRF Client"}
                emptyValueText={"Global Mapping, no XTRF client specified"}
                placeholder={"Select XTRF client"}
                noOptionsText={"No XTRF client found"}
                loadingText={"Searching XTRF"}
                error={_.isArray(errors.xtrfClient)}
                helperText={
                  _.isArray(errors.xtrfClient) && errors.xtrfClient.join()
                }
                onLoading={setSaveDisabled}
              />
            </Grid>
            {false && (
              <Grid item sm={4}>
                <CustomAutocomplete
                  value={entry.tmsClient}
                  setValue={(value) => onValueChange(value, "tmsClient")}
                  related={null}
                  url={xtrfClientsEndpoint}
                  label={"TMS Client"}
                  emptyValueText={"Global Mapping, no TMS client specified"}
                  placeholder={"Select TMS client"}
                  noOptionsText={"No TMS client found"}
                  loadingText={"Searching TMS"}
                  error={_.isArray(errors.tmsClient)}
                  helperText={
                    _.isArray(errors.tmsClient) && errors.tmsClient.join()
                  }
                  onLoading={setSaveDisabled}
                />
              </Grid>
            )}
            {false && (
              <Grid item sm={4}>
                <CustomAutocomplete
                  value={entry.salesforceClient}
                  setValue={(value) => onValueChange(value, "salesforceClient")}
                  related={null}
                  url={xtrfClientsEndpoint}
                  label={"Salesforce Client"}
                  emptyValueText={"Global Mapping, no Salesforce client specified"}
                  placeholder={"Select Salesforce client"}
                  noOptionsText={"No Salesforce client found"}
                  loadingText={"Searching Salesforce"}
                  error={_.isArray(errors.salesforceClient)}
                  helperText={
                    _.isArray(errors.salesforceClient) && errors.salesforceClient.join()
                  }
                  onLoading={setSaveDisabled}
                />
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={state.tabIndex} index={1} dir={theme.direction}>
          <Equivalencies
            equivalencies={entry.equivalencies}
            entry={entry}
            errors={errors}
            onAllCheckboxChange={onAllCheckboxChange}
            setEquivalencies={onEquivalenciesChange}
            loading={!state.tabTransitionEnded}
          />
        </TabPanel>
      </SwipeableViews>
    </React.Fragment>
  );
}
